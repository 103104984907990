import React from 'react';
import LocationPageTemplate from '../../components/Location/LocationPageTemplate';

function UsaOffice() {
  return (
    <LocationPageTemplate
      mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.146509889783!2d-122.40437178445447!3d37.78660611925127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858087e4a4fdd9%3A0x5d54a80ea8d97eed!2s95%203rd%20St%202nd%20Floor%2C%20San%20Francisco%2C%20CA%2094103%2C%20USA!5e0!3m2!1sen!2s!4v1620320113934!5m2!1sen!2s"
      analyticsPageviewTitle="Location/Usa"
      city="USA"
      addresses={[
        {
          title: 'Tintash Inc',
          description: '95 Third Street, 2nd Floor, San Francisco, CA 94103',
        },
      ]}
      locationImgSrc={require('../../assets/images/location/usa.png').default}
    />
  );
}

export default UsaOffice;
